import { createFeatureManager } from '@justeat/feature-management'
import { getRestaurantId } from '@je-pc/auth'
import { trackCustomEvent } from '@je-pc/tracking'
import { TENANT, FM_STAGE, FM_STAGE_KEY } from '../constants'
import Logger from '@/services/logger'

const SCOPE = 'jet-partner-centre-reviews'
const FM_HOST = 'https://features.api.justeattakeaway.com'

function trackExperiment(key, variant) {
  trackCustomEvent('trackExperimentV3', {
    experiment: {
      key,
      variant,
      platform: 'feature_management',
      version: 1,
      deviceTimestamp: new Date().toISOString()
    },
    platformData: {
      country: TENANT.toUpperCase()
    }
  })
}

const featureManagement = createFeatureManager({
  keyPrefix: SCOPE,
  getContext: () => ({
    restrictionsProviders: new Map().set('Country', () => TENANT.toUpperCase())
  }),
  cdn: {
    poll: false,
    host: FM_HOST,
    environment: FM_STAGE,
    scope: SCOPE,
    key: FM_STAGE_KEY
  },
  contextGetter: () => ({
    country: TENANT,
    anonUserId: getRestaurantId(),
    appVersion: '0'
  }),
  onTrack: trackExperiment
})

export const getFeature = async (key, type) => {
  const getFeatureValue = (key, type) => {
    switch (type) {
      case Boolean:
        return featureManagement.getBooleanValue(key)
      case Number:
        return featureManagement.getIntegerValue(key)
      case String:
        return featureManagement.getStringValue(key)
    }
  }
  try {
    await featureManagement.loadFromCdn()
    return getFeatureValue(key, type)
  } catch (error) {
    Logger.error(error)
  }
}
