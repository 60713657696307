<template>
  <div class="yourrating p-4 flex flex-col">
    <div class="">
      <div class="yourrating__topsection">
        <div class="yourrating__topsection__heading">
          {{ $t('your_rating') }}
        </div>
        <div class="yourrating__topsection__score">
          <div class="yourrating__topsection__score__text">
            {{ calculateScoreDisplay }}
          </div>
          <!-- TODO: for now can only have one width (size of star): using 24px as mobile is more in use.  Design specifies 24px mob 32px desktop -->
          <pc-rating
            :width="24"
            :max="maxRating"
            :rating="calculateScoreDisplay"
          />
        </div>
      </div>
      <pie-assistive-text variant="default" data-cy="reviewsAmount">
        {{
          calculateScoreDisplay
            ? $t('based_on', { num: summary.ratingCountUsedInScore })
            : $t('no_reviews')
        }}
      </pie-assistive-text>
    </div>
    <template v-if="summary.ratingCountUsedInScore">
      <pie-divider
        class="my-4"
        variant="default"
        orientation="horizontal"
      ></pie-divider>
      <div>
        <pie-link
          @click="toggleInfo"
          class="w-full flex font-bold items-center justify-between cursor-pointer leading-normal"
          tag="button"
          rel="noopener"
          variant="default"
          isstandalone
          underline="reversed"
          data-cy="openRatingBreakdown"
        >
          {{ isOpened ? $t('hide_breakdown') : $t('view_breakdown') }}
          <svg
            class="transition duration-300 ease-in-out w-6 h-6"
            :class="{ 'transform rotate-180': isOpened }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </pie-link>
        <collapse-transition>
          <div v-if="isOpened">
            <donut-chart v-if="chartItems.length" :items="chartItems" />
            <p class="font-bold text-xl">{{ $t('what_customers_think') }}</p>
            <p class="text-sm text-grey-50 leading-normal mt-2">
              {{ $t('monthly_data', { num: summary.ratingCountUsedInScore }) }}
            </p>
            <div
              class="flex flex-col space-y-6 mt-6"
              data-cy="customerThoughts"
            >
              <rating-bar
                :name="$t('food_quality')"
                :percent="foodPositive"
              ></rating-bar>
              <rating-bar
                :name="$t('delivery_time')"
                :percent="deliveryPositive"
              ></rating-bar>
            </div>
          </div>
        </collapse-transition>
      </div>
    </template>
  </div>
</template>

<script>
import '../styles/typography.css'
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/divider.js'
import '@justeattakeaway/pie-webc/components/assistive-text.js'
import { PcRating, CollapseTransition } from '@je-pc/ui-components'
import { trackCustomEvent } from '@je-pc/tracking'
import { mapState } from 'vuex'
import RatingBar from '@/components/RatingBar.vue'
import DonutChart from '@/components/DonutChart.vue'
import { getMaxRating } from '../utils/maxRating'

export default {
  data() {
    return {
      isOpened: false
    }
  },
  components: {
    PcRating,
    CollapseTransition,
    RatingBar,
    DonutChart
  },
  methods: {
    toggleInfo() {
      this.isOpened = !this.isOpened
      if (this.isOpened) {
        trackCustomEvent('trackEvent', { event_name: 'view_ratings_summary' })
      }
    },
    getReviewValues(summary) {
      if (!summary) return null
      const {
        goodRatingsCount,
        neutralRatingsCount,
        badRatingsCount,
        total = goodRatingsCount + neutralRatingsCount + badRatingsCount
      } = summary
      return {
        goodRatingsCount,
        neutralRatingsCount,
        badRatingsCount,
        total
      }
    }
  },
  computed: {
    ...mapState(['summary']),
    calculateScoreDisplay() {
      if (this.summary.ratingCountUsedInScore === 0) {
        return 0
      }
      return this.summary.ratingAverage
    },
    foodPositive() {
      const values = this.getReviewValues(this.summary.ratingsBreakdown.quality)
      if (!values.total) {
        return 0
      }
      return Math.ceil((values.goodRatingsCount * 100) / values.total)
    },
    deliveryPositive() {
      const values = this.getReviewValues(
        this.summary.ratingsBreakdown.delivery
      )
      if (!values.total) {
        return 0
      }
      return Math.ceil((values.goodRatingsCount * 100) / values.total)
    },
    chartItems() {
      const items = this.getReviewValues(this.summary.ratingsBreakdown.overall)
      return items
        ? [
            {
              label: 'positive',
              color: '#017a39',
              count: items.goodRatingsCount
            },
            {
              label: 'neutral',
              color: '#ffb727',
              count: items.neutralRatingsCount
            },
            {
              label: 'negative',
              color: '#d50525',
              count: items.badRatingsCount
            }
          ]
        : []
    },
    maxRating() {
      return getMaxRating()
    }
  }
}
</script>

<style scoped>
.yourrating {
  position: relative;
  background-color: var(--dt-color-container-default);
  /* box-shadow: var(--dt-elevation-card); TODO: this is overridden by rmp-design-tokens.css in Partner Hub, have asked PH to update their package & will adjust here once done */
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.24),
    0px 1px 5px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: var(--dt-radius-rounded-c, 12px);
  padding: var(--dt-spacing-d, 16px);
}
.yourrating__topsection {
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-b, 8px);
}
.yourrating__topsection__score {
  display: flex;
  flex-direction: row;
  gap: var(--dt-spacing-b, 8px);
}

@media screen and (min-width: 1024px) {
  .rating-icon {
    width: 136px;
  }
  .yourrating {
    padding: var(--dt-spacing-e);
  }
  .yourrating__topsection {
    display: flex;
    flex-direction: column;
    gap: var(--dt-spacing-b, 8px);
  }
  .yourrating__topsection__score {
    display: flex;
    flex-direction: row;
  }
}
</style>
