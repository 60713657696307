<template>
  <div class="reviewresponse">
    <pie-button
      class="reviewresponse__btn"
      size="xsmall"
      type="button"
      variant="secondary"
      isResponsive
      slot="reply"
      iconPlacement="leading"
      @click="handleEdit()"
      v-if="!isEditing"
      data-cy="clickReply"
    >
      <icon-chat-conversation
        slot="icon"
        class="reviewresponse__btn__icon--color"
      ></icon-chat-conversation>
      {{ $t('reply') }}
    </pie-button>

    <div class="reviewresponse" v-if="isEditing">
      <pie-textarea
        class="reviewresponse__textarea"
        :value="response"
        @input="event => (response = event.target.value)"
        ref="review"
        data-cy="replyTextBox"
        name="response-textarea"
        :assistiveText="$t('all_reviews_checked')"
        :label="$t('your_response')"
        maxLength="500"
        resize="auto"
        size="small"
      ></pie-textarea>

      <div class="reviewresponse__footer">
        <pie-button
          type="button"
          size="xsmall"
          variant="outline"
          slot="Cancel"
          isResponsive
          @click="cancel"
          class="reviewresponse__footer__btn reviewresponse__footer__btn--leading"
          data-cy="cancelButton"
        >
          {{ $t('cancel') }}
        </pie-button>
        <pie-button
          type="button"
          size="xsmall"
          variant="primary"
          slot="Reply"
          isResponsive
          @click="save"
          :loading="buttonLoading"
          class="reviewresponse__footer__btn"
          data-cy="saveButton"
        >
          {{ $t('reply') }}
        </pie-button>
      </div>
    </div>
  </div>
</template>

<script>
import '@justeattakeaway/pie-webc/components/button.js'
import '@justeattakeaway/pie-webc/components/textarea.js'
import '@justeattakeaway/pie-icons-webc/dist/IconChatConversation.js'
import { trackFormAction } from '../gtm'
import { SUBMIT_REVIEW } from '../store/actions.types'

export default {
  props: {
    id: {
      type: String,
      rquired: true
    }
  },
  data() {
    return {
      isEditing: false,
      response: '',
      buttonLoading: false
    }
  },
  methods: {
    cancel() {
      this.isEditing = false
      this.response = ''
    },
    async save() {
      this.buttonLoading = true
      const data = {
        id: this.id,
        reviewReply: this.response
      }
      trackFormAction(this.id, 'submit', this.response)
      await this.$store.dispatch(SUBMIT_REVIEW, data)
      trackFormAction(this.id, 'success', this.response)
      this.isEditing = false
      this.response = ''
      this.buttonLoading = false
    },
    handleEdit() {
      this.isEditing = true
      trackFormAction(this.id, 'start')
    }
  }
}
</script>

<style scoped>
.reviewresponse {
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-d, 16px);
}
.reviewresponse__footer {
  display: flex;
  justify-content: space-between;
  gap: var(--dt-spacing-d, 16px);
}
.reviewresponse__footer__btn {
  flex-grow: 1;
  --btn-inline-size: 100%; /* isFullWidth btn prop sets this, we need to do this manually as differs btw mobile/desktop */
}
.reviewresponse__btn__icon--color {
  color: var(--dt-color-content-interactive-brand);
}
@media screen and (min-width: 1024px) {
  .reviewresponse__footer {
    justify-content: flex-end;
  }
  .reviewresponse__footer__btn {
    --btn-inline-size: auto;
  }
  .reviewresponse__footer__btn--leading {
    margin-left: 100%;
  }
}
</style>
