import { Logger } from '@je-pc/utils'

export default new Logger({
  packageName: '@je-pc/reviews',
  release: '1.0.0',
  sentryOptions: {
    dsn:
      'https://586b0252f8bf4ea994e923fa3591eba8@o408587.ingest.sentry.io/4505600564985856'
  }
})
