<template>
  <div class="animate-pulse flex flex-col md:flex-row space-x-0 md:space-x-6">
    <div
      class="flex-auto order-2 md:order-none w-full md:w-6/12 lg:w-3/6 space-y-4"
    >
      <div
        class="bg-grey-30 p-4 border border-solid border-grey-30 md:border-0 shadow-none md:shadow-md rounded-sm"
        v-for="i in 3"
        :key="i"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
    <div class="w-full md:w-5/12 lg:w-2/6">
      <div class="flex-grow-0 order-1 md:order-none bg-grey-30">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div
        class="hidden sm:flex flex-grow-0 order-3 md:order-none mt-4 bg-grey-30"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
