var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviewcard"},[_c('div',{staticClass:"reviewcard__grid"},[_c('div',{staticClass:"reviewcard__grid__custcontent"},[_c('div',{staticClass:"reviewcard__grid__custcontent__container"},[_c('p',{staticClass:"reviewcard__grid__custcontent__heading",attrs:{"data-cy":"reviewerName"}},[_vm._v(" "+_vm._s(_vm.review.customerFirstName)+" ")]),_c('p',{staticClass:"reviewcard__grid__bodytext--bold items-center inline-flex"},[_c('span',{staticClass:"leading-none",attrs:{"data-cy":"userReviewMark"}},[_vm._v(" "+_vm._s(_vm.review.ratingAverage)+" ")]),_c('pc-rating',{staticClass:"ml-1",attrs:{"width":20,"max":_vm.maxRating,"rating":_vm.review.ratingAverage}})],1)])]),_c('div',{staticClass:"reviewcard__grid__metacontent"},[_c('div',{staticClass:"reviewcard__grid__metacontent__container"},[_c('div',{staticClass:"reviewcard__grid__metacontent__container__item1"},[_c('p',{staticClass:"reviewcard__grid__bodytext--regular",attrs:{"data-cy":"reviewDate"}},[_vm._v(" "+_vm._s(_vm.format(new Date(_vm.review.reviewDate), 'd MMM yyyy'))+" ")])]),_c('div',{staticClass:"reviewcard__grid__metacontent__container__item2"},[_c('pie-link',{attrs:{"rel":"noopener","tag":"a","variant":"default","data-cy":"orderlink","href":_vm.getOrderLink(_vm.review.orderNumber)}},[_vm._v(" "+_vm._s(_vm.$t('order_n', { num: _vm.review.orderNumber }))+" ")]),_c('pie-icon-button',{attrs:{"size":"small","variant":"ghost-secondary"},on:{"click":function($event){return _vm.copyIdToClipboard(_vm.review.orderNumber)}}},[_c('span',{directives:[{name:"pc-tooltip",rawName:"v-pc-tooltip",value:({
                placement: 'bottom',
                content: _vm.$t('copy_order_number')
              }),expression:"{\n                placement: 'bottom',\n                content: $t('copy_order_number')\n              }"}]},[_c('icon-copy')],1)])],1)])]),_c('div',{staticClass:"reviewcard__grid__review"},[_c('div',{staticClass:"reviewcard__grid__review__comment"},[_c('p',{staticClass:"reviewcard__grid__bodytext--regular",attrs:{"data-cy":"reviewerComment"}},[_vm._v(" "+_vm._s(_vm.review.customerComments)+" ")])]),(_vm.isReplyEnabled)?[_c('div',{staticClass:"reviewcard__grid__review__reply"},[(_vm.review.restaurantComments)?_c('div',{staticClass:"reviewcard__grid__bodytext--regular reviewcard__grid__review__reply__container",attrs:{"data-cy":"userReviewText"}},[_c('div',{staticClass:"reviewcard__grid__bodytext--bold reviewcard__grid__review__reply__header",attrs:{"data-cy":"youRepliedText"}},[_vm._v(" "+_vm._s(_vm.$t('you_replied'))+" "),(!_vm.review.restaurantCommentApproved)?_c('pie-tag',{directives:[{name:"pc-tooltip",rawName:"v-pc-tooltip",value:({
                  placement: 'right',
                  content: _vm.$t('all_reviews_checked')
                }),expression:"{\n                  placement: 'right',\n                  content: $t('all_reviews_checked')\n                }"}],staticClass:"reviewcard__grid__review__reply--pending",attrs:{"slot":_vm.$t('pending_approval'),"data-cy":"pendingApprovalText","variant":"neutral","isStrong":"","size":"large"},slot:_vm.$t('pending_approval')},[_vm._v(" "+_vm._s(_vm.$t('pending_approval'))+" ")]):_vm._e()],1),_vm._v(" "+_vm._s(_vm.review.restaurantComments)+" ")]):_c('review-response',{staticClass:"reviewcard__grid__footer",attrs:{"id":_vm.review.reviewId}})],1)]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }