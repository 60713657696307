import { ApiClient } from '@je-pc/api'
import { SMARTGATEWAY_URL } from '../constants'

const http = new ApiClient(SMARTGATEWAY_URL, true, true)

export const getReviewsSummary = () => {
  return http.get(
    '/applications/partnerreviews/{tenant}/{restaurantId}/reviewssummary'
  )
}

export const getReviews = params => {
  return http.get('/restaurants/{tenant}/{restaurantId}/unfiltered-reviews', {
    params: {
      ...params,
      limit: 20 // raised to 20 for now, will monitor and raise again for review insights if all ok
    }
  })
}

export const submitResponse = (id, data) => {
  return http.post(`/reviews/{tenant}/${id}/replies`, data)
}
