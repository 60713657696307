<template>
  <pc-error-boundary @on-error="handleComponentError">
    <div class="app-container">
      <pc-page-transition>
        <router-view />
      </pc-page-transition>
    </div>
  </pc-error-boundary>
</template>

<script>
import { PcErrorBoundary, PcPageTransition } from '@je-pc/ui-components'
import logger from '@/services/logger'

export default {
  components: {
    PcPageTransition,
    PcErrorBoundary
  },
  methods: {
    handleComponentError({ error, loggerPayload }) {
      logger.error(error, loggerPayload)
    }
  }
}
</script>

<style scoped>
.app-container {
  width: 100%;
  margin: 0 auto;
}
</style>
