import Vue from 'vue'
import Vuex from 'vuex'
import { getReviewsSummary, getReviews, submitResponse } from '../services/http'
import {
  GET_REVIEWS,
  GET_REVIEWS_NEXT,
  GET_REVIEWS_SUMMARY,
  SUBMIT_REVIEW
} from './actions.types'
import {
  SET_REVIEWS,
  SET_NEW_REVIEWS,
  SET_REVIEWS_SUMMARY,
  SET_CURSOR,
  SET_IS_LOADING_INFINITE,
  CHANGE_REVIEW
} from './mutations.types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reviews: [],
    cursor: '',
    isLoadingInfinite: false,
    summary: null
  },
  mutations: {
    [SET_REVIEWS](state, reviews) {
      state.reviews = reviews
    },
    [SET_NEW_REVIEWS](state, reviews) {
      state.reviews = [...state.reviews, ...reviews]
    },
    [SET_REVIEWS_SUMMARY](state, summary) {
      state.summary = summary
    },
    [SET_CURSOR](state, cursor) {
      state.cursor = cursor
    },
    [SET_IS_LOADING_INFINITE](state, value) {
      state.isLoadingInfinite = value
    },
    [CHANGE_REVIEW](state, data) {
      const review = state.reviews.find(review => review.reviewId === data.id)
      Vue.set(review, 'restaurantComments', data.reviewReply)
    }
  },
  actions: {
    async [GET_REVIEWS]({ commit }, params) {
      const response = await getReviews(params)
      commit(SET_REVIEWS, response.data.reviews)
      commit(SET_CURSOR, response.data.paging.cursors.after)
    },
    async [GET_REVIEWS_NEXT]({ state, commit }, params) {
      if (!state.cursor || state.isLoadingInfinite) return
      params = { ...params, after: state.cursor }
      commit(SET_IS_LOADING_INFINITE, true)
      const response = await getReviews(params)
      commit(SET_NEW_REVIEWS, response.data.reviews)
      commit(SET_CURSOR, response.data.paging.cursors.after)
      commit(SET_IS_LOADING_INFINITE, false)
    },
    async [GET_REVIEWS_SUMMARY]({ commit }) {
      const response = await getReviewsSummary()
      commit(SET_REVIEWS_SUMMARY, response.data)
    },
    async [SUBMIT_REVIEW]({ commit }, data) {
      const { id, ...rest } = data
      return submitResponse(id, rest).then(() => {
        commit(CHANGE_REVIEW, data)
      })
    }
  },
  getters: {
    reviewsCount(state) {
      return state.summary.reviewsCount
    }
  }
})
