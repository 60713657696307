<template>
  <!-- simple BEM example -->
  <div class="noreviews">
    <div class="noreviews__content">
      <div class="noreviews__content__heading" data-cy="noReviewText">
        {{ $t('you_dont_have_variant') }}
      </div>
      <p class="noreviews__content__bodytext">
        {{ $t('try_to_motivate_variant') }}
      </p>
    </div>
    <pie-notification
      isopen=""
      variant="info"
      position="inline-content"
      :heading="$t('more_orders_more_reviews')"
      headinglevel="h2"
      data-cy="moreOrdersNotification"
    >
      {{ $t('more_orders_more_reviews_text') }}
      <pie-link
        tag="a"
        variant="high-visibility"
        size="medium"
        isBold
        underline="reversed"
        isStandalone
        data-cy="moreOrdersLink"
        href="../marketing"
      >
        {{ $t('create_an_offer') }}
      </pie-link>
    </pie-notification>
  </div>
</template>

<script>
import '../styles/typography.css'
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/notification.js'
export default {}
</script>

<style scoped>
.noreviews {
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-e, 24px);
}

.noreviews__content {
  display: flex;
  flex-direction: column;
  gap: var(--dt-spacing-b, 8px);
}

@media screen and (min-width: 1024px) {
  .noreviews {
    padding-right: 20%;
  }
}
</style>
