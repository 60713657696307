import { trackCustomEvent } from '@je-pc/tracking'

export const trackFormAction = (id, action, comment) => {
  const trackingObject = {
    order_key: id,
    form: {
      name: 'review_reply',
      action,
      error: '',
      autofill: '',
      changes: comment ?? ''
    }
  }

  trackCustomEvent('form', trackingObject)
}
