<template>
  <div class="flex items-center space-x-0 sm:space-x-4">
    <slot />
    <div class="flex flex-1 flex-col space-y-2">
      <p class="inline-flex items-center leading-none justify-between">
        <span>{{ name }}</span>
        <span class="text-sm">
          {{ $t('positive', { num: percent }) }}
        </span>
      </p>
      <pc-progress :percent="percent" />
    </div>
  </div>
</template>

<script>
import { PcProgress } from '@je-pc/ui-components'

export default {
  components: {
    PcProgress
  },
  props: {
    name: {
      type: String,
      required: true
    },
    percent: {
      type: Number,
      required: true
    }
  }
}
</script>
