<template>
  <div class="flex my-8">
    <svg height="160" width="160" viewBox="0 0 160 160" class="donut-chart">
      <g
        class="cursor-pointer"
        v-for="(item, index) in sortedItems"
        :key="index"
      >
        <circle
          v-if="item.count"
          :cx="cx"
          :cy="cy"
          :r="radius"
          :stroke="item.color"
          :stroke-width="strokeWidth"
          :stroke-dasharray="adjustedCircumference"
          :stroke-dashoffset="
            calculateStrokeDashOffset(item.count, circumference)
          "
          fill="transparent"
          :transform="returnCircleTransformValue(index)"
        />
      </g>
      <text
        class="transform -translate-x-4 text-2xl font-bold"
        data-cy="breakdownReviewsAmount"
        :x="totalCount <= 9 ? 90 : cx"
        :y="cy"
      >
        {{ totalCount }}
      </text>
      <text class="transform -translate-x-6 translate-y-5" :x="cx" :y="cy">
        {{ $tc('reviews_label', totalCount) }}
      </text>
    </svg>
    <div
      class="flex flex-col space-y-4 justify-center items-start w-2/3"
      data-cy="inlineRatingAmount"
    >
      <span
        class="inline-flex items-center leading-normal text-sm md:text-base ml-4 lg:ml-8 space-x-4"
        v-for="(item, index) in sortedItems"
        :key="index"
      >
        <span
          class="rounded-full h-3 w-3 block"
          :style="{ 'background-color': item.color }"
        />
        <span>
          {{ $t(item.label, { num: labelValue(item.count, index) }) }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      angleOffset: -90,
      chartData: [],
      cx: 80,
      cy: 80,
      radius: 60,
      sortedItems: [],
      strokeWidth: 20
    }
  },
  computed: {
    adjustedCircumference() {
      return this.circumference - 2
    },
    circumference() {
      return 2 * Math.PI * this.radius
    },
    totalCount() {
      return (
        this.sortedItems.length &&
        this.sortedItems.reduce((acc, item) => acc + item.count, 0)
      )
    }
  },
  methods: {
    calculateStrokeDashOffset(count, circumference) {
      const strokeDiff = this.dataPercentage(count) * circumference
      return circumference - strokeDiff
    },
    degreesToRadians(angle) {
      return angle * (Math.PI / 180)
    },
    dataPercentage(count) {
      return count / this.totalCount
    },
    returnCircleTransformValue(index) {
      return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`
    },
    calculateChartData() {
      this.sortedItems.forEach(item => {
        const data = {
          degrees: this.angleOffset
        }
        this.chartData.push(data)
        this.angleOffset =
          this.dataPercentage(item.count) * 360 + this.angleOffset
      })
    },
    sortItems() {
      return (this.sortedItems = this.items.sort((a, b) => b.count - a.count))
    },
    labelValue(count, i) {
      const percent = this.dataPercentage(count) * 100
      return i === 0 ? Math.ceil(percent) : Math.floor(percent)
    }
  },
  mounted() {
    this.sortItems()
    this.calculateChartData()
  }
}
</script>

<style></style>
