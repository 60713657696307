<template>
  <div>
    <pie-notification
      isopen=""
      variant="info"
      position="inline-content"
      :heading="$t('responding_to_reviews_title')"
      headinglevel="h2"
      data-cy="respondingToReviewsNotification"
    >
      {{ $t('responding_to_reviews') }}
      <pie-link
        tag="button"
        variant="high-visibility"
        size="medium"
        data-cy="respondingToReviewsLink"
        rel="noopener"
        target="_blank"
        @click="$emit('show')"
      >
        {{ $t('read_more') }}
      </pie-link>
    </pie-notification>
  </div>
</template>

<script>
import '@justeattakeaway/pie-webc/components/link.js'
import '@justeattakeaway/pie-webc/components/notification.js'
export default {}
</script>
